import React, { Fragment, Suspense, useEffect } from 'react';
import { Routes, Route, Link, useLocation } from "react-router-dom";
import Home from '../Home/Home';
import Footer from '../../components/Footer/Footer';
import Navigation from '../../components/Navigation/Navigation';
import CookieConsent, {getCookieConsentValue} from 'react-cookie-consent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* dynamic routes */
const Vehicle = React.lazy(() => import('../../containers/Vehicle/Vehicle'));
const Vehicles = React.lazy( () => import('../../containers/Vehicles/Vehicles'));
const Reviews = React.lazy(() => import( '../../containers/Reviews/Reviews'));
const Specials = React.lazy(() => import( '../../containers/Specials/Specials'));
const Terms = React.lazy(() => import( '../../containers/Terms/Terms'));
const Faq = React.lazy(() => import( '../../containers/Faq/Faq'));
const Privacy = React.lazy(() => import( '../../containers/Privacy/Privacy'));
const Imprint = React.lazy(() => import( '../../containers/Imprint/Imprint'));

export function Webpage(props) {
  const {t, i18n} = props
  
  const loadingContent = <div className='container-fluid loading'></div>;

  let cookieConsent = null;
  if (!getCookieConsentValue()) {
    cookieConsent = 
      <CookieConsent 
        location="top" 
        buttonText={<div><FontAwesomeIcon className="text-secondary" icon={["fas", "check"]} /><span> OK</span></div>}
        buttonStyle={{margin: "5px"}} 
        style={{lineHeight: "1.1", fontSize: "0.6rem"}}
        contentStyle={{flex: "1 0 200px"}}
      >
        {t('common:usingCookies') + ' '} 
        <Link to='/privacy' style={{color:"rgb(255 212 45)"}}>{t('common:moreDetails')}</Link>
      </CookieConsent>
  }
  
  const {pathname, hash} = useLocation();
  useEffect(() => {
      // if not a hash link scroll to top
      if(hash===''){
          window.scrollTo(0, 0)
      }
      // else scroll to id
      else{
          setTimeout(
              () => {
                  const id = hash.replace('#', '');
                  const element = document.getElementById(id);
                  if (element) {
                      element.scrollIntoView();
                  }
              },
              0
          );
      }
  }, [pathname, hash]) // do this on route change

  return (
    <Fragment>
      {cookieConsent}
        <Routes>
          {/*<Route path="(.+)" element={(o) => switchLanguage(i18n, o.location.search)} />*/}
          {/*home*/}
          <Route path="/" element={<Home />} />
          <Route path="/specials" element={
              <Fragment>
                <Navigation />
                <Suspense fallback={loadingContent}>
                  <Specials t={t} i18n={i18n} /> 
                </Suspense>
              </Fragment>
           }
          />
          <Route path="/reviews" element={
              <Fragment>
                <Navigation />
                <Suspense fallback={loadingContent}>
                  <Reviews t={t} i18n={i18n} /> 
                </Suspense>
              </Fragment>
           }
          />
          <Route path="/faq" element={
              <Fragment>
                <Navigation />
                <Suspense fallback={loadingContent}>
                  <Faq lang={i18n.language} /> 
                </Suspense>
              </Fragment>
            }
          />
          <Route path="/terms" element={
              <Fragment>
                <Navigation />
                <Suspense fallback={loadingContent}>
                  <Terms lang={i18n.language} />
                </Suspense>
              </Fragment>
            } 
          />
          <Route path="/privacy" element={
              <Fragment>
                <Navigation />
                <Suspense fallback={loadingContent}>
                  <Privacy lang={i18n.language} />
                </Suspense>
              </Fragment>
            }
          />
          <Route path="/imprint" element={
              <Fragment>
                <Navigation />
                <Suspense fallback={loadingContent}>
                  <Imprint lang={i18n.language} />
                </Suspense>
              </Fragment>
            }
          />        
          <Route path="/vehicles" element={ 
              <Fragment>
                <Navigation />
                <Suspense fallback={loadingContent}>
                  <Vehicles t={t} />
                </Suspense>
              </Fragment>
            } 
          />  
          <Route path={`/vehicles/:camperId`} element={ 
              <Fragment>
                <Navigation />
                <Suspense fallback={ loadingContent } >
                  <Vehicle {...props} />
                </Suspense>
              </Fragment>
            } 
          />
        </Routes>
      <Footer />
    </Fragment>
  );  
}

export default Webpage
